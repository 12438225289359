import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** static sections */
import FAQGeneralSection from "../components/static-sections/FAQGeneralSection"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import HeroBottomSection from "../components/dynamic-sections/HeroBottomSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import TwoImageSection from "../components/dynamic-sections/TwoImageSection"
import HeroRightSection from "../components/dynamic-sections/HeroRightSection"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** svg */
import NorhartIcon from "../../assets/norhart-icon.svg"
import SupportGuysIcon from "../../assets/support-guys-icon.svg"
import StarReviewIcon from "../../assets/five-star-rating.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  data: any
}

/** const */
const SupportPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/support/",
          name: "Support | Norhart",
          image: `${config.siteMetadata.siteUrl}/support/support-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Support | Norhart"
      description="Our top goal at Norhart is to make sure our residents have a fantastic experience at our apartments. Our teams are on the job 24/7, working for you."
      keywords="norhart, luxury, innovative, smart, technology, apartment, apartments, building, buildings, mn, minnesota, minneapolis, st paul, rent, rentals, resident, residents, forest lake, blaine, circle pines, lexington, coon rapids, Oakdale, encore apartments, lexington lofts apartments, Oakdale apartments, gateway green apartments, mill pond apartments, birchview apartments, legacy pointe apartments, heritage heights apartments, springwood apartments, greystone apartments, northbrook apartments, construction, norhart construction, lean construction"
      imageTwitter={`${config.siteMetadata.siteUrl}/support/norhart-support-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/support/norhart-support-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Support FAQ"
        subtitle="Helping Apartment Residents Is What We 🤍"
        imageTitle="Norhart Support"
        image="/support/norhart-support-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Apartment Residents"
        tagLine="Helping you is our number one priority"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Support"
        title="Don't Worry, We Got You"
        subTitle="Our top goal at Norhart is to make sure our residents have a fantastic experience at our apartments. Our teams are on the job 24/7."
        image="/support/norhart-support-team.png"
        imageAlt="Norhart Support Team"
        textColor={colorPalette.textColor}
        asoEffect="fade-in"
      />

      <TwoImageSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        icon={SupportGuysIcon}
        iconTitle="Norhart Support"
        title="Our Focus Is You"
        subtitle="We know when an unexpected issue arises, it needs to be addressed and resolved as quickly as possible. We are committed to providing you with the best support possible. #NorhartSupport"
        imageTitleOne="Your Experience"
        imageOneText="We care deeply about your Norhart experience. We know when unexpected issues arise, they need to be addressed and resolved as quickly as possible. We are committed to providing you with the best support experience possible."
        imageOne="/support/norhart-resident-experience.webp"
        imageOneAlt="Norhart Resident Experience"
        imageOnePill=""
        imageTitleTwo="We Are Here To Help"
        imageTwoText="Have an issue that needs to be resolved? No problem, just login into your Norhart account and fill out a service request. One of our team members will be on it immediately. Just click the Create Service Request button above to get started."
        imageTwo="/support/norhart-submit-support-request.webp"
        imageTwoAlt="Norhart Submit Support Request"
        imageTwoPill=""
        pillActive={false}
        colorPalette={colorPalette}
        buttonName="Create Service Request"
        buttonUrl="https://norhart.twa.rentmanager.com"
      />

      <FAQGeneralSection colorPalette={colorPalette} />

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Reviews"
        title="Our Residents Say It Best"
        starRating="yes"
        subTitle={`"If there’s a mistake they fix it. The management team is AMAZING!! The type of people you want to be friends with. The apartments are absolutely gorgeous as well!" - Viviana, Norhart Resident`}
        image="/support/norhart-resident-reviews-2.png"
        imageAlt="Norhart Resident Reviews"
        textColor={colorPalette.textColor}
        asoEffect="zoom-in"
      />

      <HeroRightSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        titleTopLeft="Online Chat"
        badgeTitle="How Can We Help?"
        title="Hello Norbot"
        subtitle={`Do you need some help finding that perfect apartment or have a question? Chat with our Sales team. If we are not online, "Norbot" will take your message.`}
        imageTitle="Norhart Norbot AI Chat"
        image="/support/norhart-norbot-chat.png"
        colorPalette={colorPalette}
        colSizeOne={5}
        colSizeTwo={7}
        bottomMargin={0}
        asoEffect="fade"
      />

      <AwardsSection
        header="Norhart Apartments"
        title="What Drives Us"
        subtitle="We invite you to explore our Forest Lake and Blaine Minnesota smart apartments. Once you experience Norhart's smart living, believe me, there is no going back!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default SupportPage
