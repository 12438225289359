import React from "react"
import { ColorPalette } from "../Theme"
import Accordion from "react-bootstrap/Accordion"

/** props */
interface Props {
  colorPalette: ColorPalette
}

/** const */
const FAQGeneralSection: React.FC<Props> = (props) => {
  return (
    <section
      className="container-fluid pb-5 pt-0"
      style={{
        background: "linear-gradient(#EFF2F4, #EFF2F4)",
        color: "#365c70",
      }}
    >
      <div className="container pl-3 pr-3">
        <div className="container pb-5"></div>
        <div className="contentHeroSpace hidden-xs"></div>
        <h2
          className="contentHeroTitle"
          style={{
            color: "#365c70",
          }}
        >
          Frequently Asked Questions
        </h2>
        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line mt-5 mb-5"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
          }}
        />

        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Does Norhart offer short term leases?</Accordion.Header>
            <Accordion.Body>
              We sure do! We offer a wide variety of lease terms (both short and long) to make your stay with us
              affordable, fun, and enjoyable.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>
              Can I use the movie room and the community room once I become a Norhart resident?
            </Accordion.Header>
            <Accordion.Body>
              Yes! Once you become a resident, we will give you instructions on scheduling a time to watch the big game
              in the community room or enjoy the latest Star Wars, Marvel, or Pixar movie in the movie room.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>What sort of equipment is in the Norhart apartment Fitness Center?</Accordion.Header>
            <Accordion.Body>
              The fitness center is fully stocked with brand new equipment to help you stay fit. For those individuals
              interested in a cardio-focused workout, the fitness center has an array of treadmills and stationary
              bikes. For those individuals who want to lift weights, the fitness center has a large selection of
              dumbbells and cable machines. No matter what your goals are, work out in our health and fitness centers
              today!
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>Does Norhart allow me to grill on my patio?</Accordion.Header>
            <Accordion.Body>
              Unfortunately, due to safety risks, we must ask that our residents do not grill on their patio. We care
              about the safety of our residents and want to do everything in our power to make their time with us
              enjoyable, relaxing, and safe.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>My credit score isn't great. Can I still rent a Norhart apartment?</Accordion.Header>
            <Accordion.Body>
              That is a great question. At Norhart, we want to make sure that everyone gets a fair and equal opportunity
              to be accepted into our apatments which is why we run a criminal and financial background check on all of
              our residents. If you are interested in renting a unit from us, we encourage you to apply. We won't know
              whether or not we can accept you until after the background check.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5">
            <Accordion.Header>Can I smoke in a Norhart apartment building?</Accordion.Header>
            <Accordion.Body>
              Our buildings are smoke free which means that residents may not smoke cigarettes, pipes, cigars, or any
              form of e-cigarette in their unit, nor on their balcony. We do have designated smoking areas outside of
              our buildings where residents are free to smoke at their leisure.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6">
            <Accordion.Header>Can I store my bike on my Norhart apartment patio?</Accordion.Header>
            <Accordion.Body>
              Unfortunately, due to health and safety concerns, we ask all of our residents to find another solution for
              storing their bicycles. All of our buildings (except for Encore) are equipped with a bike rack in the
              garage. For more information, please speak with a member of our friendly leasing staff.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="7">
            <Accordion.Header>Does Norhart accept Section 8?</Accordion.Header>
            <Accordion.Body>At this time, we do not. We are sorry about that.</Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </section>
  )
}

/** export */
export default FAQGeneralSection
